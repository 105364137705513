import GiftsService from '../../../../../../../services/api/gifts'
import GiveawayService from '../../../../../../../services/api/giveaways'
import UsersService from '../../../../../../../services/api/users'

export const SET_RECIPIENT = 'createGift/SET_RECIPIENT'
export const LOAD_GIVEAWAYS = 'createGift/LOAD_GIVEAWAYS'
export const GIVEAWAY_LOADING = 'createGift/GIVEAWAY_LOADING'
export const SET_GIVEAWAY = 'createGift/SET_GIVEAWAY'
export const SET_RECIPIENT_TYPE = 'createGift/SET_RECIPIENT_TYPE'
export const SET_GIVEAWAY_TITLE = 'createGift/SET_GIVEAWAY_TITLE'
export const SET_TITLE = 'createGift/SET_TITLE'
export const SET_PRODUCT = 'createGift/SET_PRODUCT'
export const REM_PRODUCT = 'createGift/REM_PRODUCT'
export const UPDATE_PRODUCT = 'createGift/UPDATE_PRODUCT'
export const SET_SHOPID = 'createGift/SET_SHOPID'
export const SET_ERPID = 'createGift/SET_ERPID'
export const SET_SHIPPING = 'createGift/SET_SHIPPING'
export const SET_NOTE = 'createGift/SET_NOTE'
export const SET_WHMAIL = 'createGift/SET_WHMAIL'
export const SET_INFMAIL = 'createGift/SET_INFMAIL'
export const SET_SHOULD_INF_MSG = 'createGift/SET_SHOULD_INF_MSG'
export const SET_SHIPMENT_SOURCE = 'createGift/SET_SHIPMENT_SOURCE'
export const CLEANUP = 'createGift/CLEANUP'
export const TEMP_ADDRESS_SET = 'createGift/TEMP_ADDRESS_SET'
export const CHANGE_HIDE_ADDRESS = 'createGift/CHANGE_HIDE_ADDRESS'
export const CHANGE_OUT_OF_STOCK = 'createGift/CHANGE_OUT_OF_STOCK'
export const SET_GIFT_BACK = 'createGift/SET_GIFT_BACK'
export const DONE_LOADING = 'createGift/DONE_LOADING'
export const INCR_PRODUCT_COUNT = 'createGift/INCR_PRODUCT_COUNT'
export const SET_PRODUCT_COUNT = 'createGift/SET_PRODUCT_COUNT'
export const SYNC_SHIPMENT_MSG = 'createGift/SYNC_SHIPMENT_MSG'
export const SET_CUSTOM_TITLE = 'createGift/SET_CUSTOM_TITLE'
export const SET_CREATION_DATE = 'createGift/SET_CREATION_DATE'
export const SET_RNDINFO = 'createGift/SET_RNDINFO'
export const TOGGLE_URGENT = 'createGift/TOGGLE_URGENT'
export const SET_EXTRACTED = 'createGift/SET_EXTRACTED'
export const SET_BUNDLE_CATALOG = 'createGift/SET_BUNDLE_CATALOG'
export const SET_BUNDLES = 'createGift/SET_BUNDLES'
export const SET_SHIPPING_ADDRESS = 'createGift/SET_SHIPPING_ADDRESS'
export const SET_SHIPMENT_ID = 'createGift/SET_SHIPMENT_ID'
export const SET_NUMERICAL_ID = 'createGift/SET_NUMERICAL_ID'
export const SET_NUMERICAL_ID_LOADER = 'createGift/SET_NUMERICAL_ID_LOADER'
export const SET_ADRS_UPDATE_TEXT = 'createGift/SET_ADRS_UPDATE_TEXT'
export const SET_GIFT_TYPE = 'createGift/SET_GIFT_TYPE'
export const SET_ERP_INTEGRATION_REQUEST =
  'createGift/SET_ERP_INTEGRATION_REQUEST'

export const setErpIntegration = (value) => (dispatch) =>
  dispatch({ type: SET_ERP_INTEGRATION_REQUEST, value })

export const saveAddress = () => {
  return (dispatch, getState) => {
    const store = getState().gifts
    // const user = {
    //   internalId: store.who.internalId,
    //   addressStreet: store.who.addressStreet,
    //   addressComplement: store.who.addressComplement,
    //   cityName: store.who.cityName,
    //   zipCode: store.who.zipCode,
    //   state: store.who.state,
    //   publicEmail: store.who.publicEmail,
    //   lifeCountry: store.who.lifeCountry,
    //   contactPhoneNumber: store.who.contactPhoneNumber,
    //   erpCountry: store.who.erpCountry,
    //   fullName: store.who.fullName,
    //   lastName: store.who.lastName,
    //   area: store.who.area,
    //   country: store.who.country
    // }

    UsersService.setAddress(store.who)
    // .then((result) => notifySuccess())
    // .catch(() => notifyError())
  }
}
export const setGiftType = (value) => (dispatch) =>
  dispatch({ type: SET_GIFT_TYPE, value })

export const updateTextProp = (prop, value) => {
  return (dispatch) => {
    dispatch({ type: SET_ADRS_UPDATE_TEXT, prop, value })
  }
}

export const setNumericalId = () => (dispatch, _getState) => {
  const state = _getState().gifts

  dispatch({ type: SET_NUMERICAL_ID_LOADER, value: true })
  GiftsService.getUniqueId().then(({ data }) => {
    dispatch({ type: SET_NUMERICAL_ID, value: data })
    dispatch({ type: SET_NUMERICAL_ID_LOADER, value: false })
    // add to note (not replace note)
    dispatch({
      type: SET_NOTE,
      value: `ID: ${data}\n${state.note}`
    })
  })
}

export const setExtracted = (value) => (dispatch) => {
  dispatch({ type: SET_EXTRACTED, value })
}

export const toggleUrgentShipping = () => (dispatch) => {
  dispatch({ type: TOGGLE_URGENT })
}

export const increaseProductFormCount = () => (dispatch) => {
  dispatch({ type: INCR_PRODUCT_COUNT })
}
export const setProductFormCount = (value) => (dispatch) => {
  dispatch({ type: SET_PRODUCT_COUNT, value })
}
export const updateAddressForShipping = (value) => (dispatch) => {
  dispatch({ type: TEMP_ADDRESS_SET, value })
}
export const changeHideAddressStatus = () => (dispatch) => {
  dispatch({ type: CHANGE_HIDE_ADDRESS })
}

export const changeOutOfStockStatus = () => (dispatch) => {
  dispatch({ type: CHANGE_OUT_OF_STOCK })
}

export const setRecipient = (value) => (dispatch) => {
  dispatch({ type: GIVEAWAY_LOADING, value: true })
  if (value) {
    UsersService.get(value.username).then(({ data }) => {
      dispatch({ type: SET_RECIPIENT, value: data })
    })

    GiveawayService.search({ usernames: [value.username] })
      .then((result) =>
        dispatch({
          type: LOAD_GIVEAWAYS,
          value: result.data.filter(
            (d) => d.winnerGiftId === null || d.winnerGiftId === ''
          )
        })
      )
      .then(() => dispatch({ type: GIVEAWAY_LOADING, value: false }))
  }
}

export const setGiveaway = (value) => (dispatch) => {
  dispatch({ type: SET_GIVEAWAY, value })
  if (value === '') {
    dispatch({ type: SET_RECIPIENT_TYPE, value })
  } else {
    dispatch({ type: SET_RECIPIENT_TYPE, value: 'winner' })
  }
}

export const setGiveawayTitle = (value) => (dispatch) => {
  dispatch({ type: SET_GIVEAWAY_TITLE, value })
}

export const setRecipientType = (value) => (dispatch) => {
  dispatch({ type: SET_RECIPIENT_TYPE, value })
}

export const setCustomTitle = (value) => (dispatch) =>
  dispatch({ type: SET_CUSTOM_TITLE, value })

export const setTitle = (value) => (dispatch) =>
  dispatch({ type: SET_TITLE, value })

export const addProduct = (value) => (dispatch, _getState) => {
  const state = _getState().gifts

  if (state.title === '') dispatch({ type: SET_TITLE, value: value.title })
  dispatch({ type: SET_PRODUCT, value })
}

export const removeBag = (value) => (dispatch) =>
  dispatch({ type: REM_PRODUCT, value })

export const updateProduct = (value) => (dispatch) =>
  dispatch({ type: UPDATE_PRODUCT, value })

export const setShopId = (value) => (dispatch) =>
  dispatch({ type: SET_SHOPID, value })

export const setErpId = (value) => (dispatch) =>
  dispatch({ type: SET_ERPID, value })

export const setShippingDate = (value) => (dispatch) =>
  dispatch({ type: SET_SHIPPING, value })

export const setCreationDate = (value) => (dispatch) =>
  dispatch({ type: SET_CREATION_DATE, value })

export const setEditorNote = (value) => (dispatch) =>
  dispatch({ type: SET_NOTE, value })

export const setEditorWarehouseNote = (value) => (dispatch) =>
  dispatch({ type: SET_WHMAIL, value })

export const setShouldAddInfluencerMessage = (value) => (dispatch) =>
  dispatch({ type: SET_SHOULD_INF_MSG, value })

export const setEditorInfluencerNote = (value) => (dispatch) =>
  dispatch({ type: SET_INFMAIL, value })

export const setShipmentSource = (value) => (dispatch, _getState) => {
  const state = _getState().gifts
  if (state.erpId && value.includes('warehouse')) {
    dispatch({ type: SET_ERPID, value: '' })
    dispatch({ type: SET_RNDINFO, value: '' })
  }
  if (!state.erpId && !value.includes('warehouse')) {
    const id = Array(6)
      .fill()
      .map((n) => ((Math.random() * 24) | 0).toString(36))
      .join('')
    dispatch({ type: SET_ERPID, value: id })
    dispatch({ type: SET_RNDINFO, value: 'Random ID Generated' })
  }
  dispatch({ type: SET_SHIPMENT_SOURCE, value })
  dispatch({ type: SYNC_SHIPMENT_MSG, value })
}

export const setShipmentSourceId = (value) => (dispatch) =>
  dispatch({ type: SET_SHIPMENT_ID, value })

export const setShipmentAddress = (value) => (dispatch) =>
  dispatch({ type: SET_SHIPPING_ADDRESS, value })

export const setBundle = (value) => (dispatch) =>
  dispatch({ type: SET_BUNDLES, value })

export const cleanupModel = () => (dispatch) => {
  dispatch({ type: CLEANUP })
  //const state = _getState().gifts
  // const cleanProducts = new Promise(() =>
  //   state.products.forEach((p) => dispatch(removeBag(p.cnt)))
  // )
  //cleanProducts.then(() => ))
}
